import React, { useContext, useEffect } from "react";
import authContext from "../context/auth/authContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Container from "../components/layout/Container";
import setAuthToken from "../helpers/setAuthToken";

const AuthenticateSocialPage = (props) => {
  const { isLoggedIn, loadUser } = useContext(authContext);
  const token = new URLSearchParams(props.location.search).get("token");

  const loginTheUser = async () => {
    localStorage.setItem("token", token);
    setAuthToken(token);
    await loadUser(token);
  };

  useEffect(() => {
    if (token) {
      loginTheUser();
    }
  }, [token]);

  if (isLoggedIn) return <Redirect to="/" />;

  return <Container />;
};

export default AuthenticateSocialPage;
