import React, { useContext } from "react";
import Container from "../components/layout/Container";
import { Redirect } from "react-router-dom";
import Heading from "../components/typography/Heading";
import Button from "../components/buttons/Button";
import { Input } from "../components/forms/Input";
import { Form } from "../components/forms/Form";
import { FormGroup } from "../components/forms/FormGroup";
import { ButtonGroup } from "../components/forms/ButtonGroup";
import RelativeWrapper from "../components/layout/RelativeWrapper";
import useScrollToTopOnPageLoad from "../hooks/useScrollToTopOnPageLoad";
import authContext from "../context/auth/authContext";
import { TiledBackgroundImage } from "../components/decoration/TiledBackgroundImage";
import styled from "styled-components";

// ** React Hook Form Imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  })
  .required();

const ForgetPasswordPage = () => {
  const { forgetPassword, isLoggedIn } = useContext(authContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { email } = data;
    forgetPassword(email);
  };

  useScrollToTopOnPageLoad();

  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <RelativeWrapper>
      <TiledBackgroundImage />
      <Container
        fullHeight
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="6rem 2rem 2rem 2rem"
        contentCenteredMobile
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Heading>
            <WhiteHeading>Forget </WhiteHeading>
            <RedHeading>Password?</RedHeading>
          </Heading>
          <SubHeading>
            Enter your email address below to reset your password and regain
            access to your account.
          </SubHeading>
          <FormGroup>
            <Input
              type="email"
              {...register("email")}
              placeholder="Enter email"
            />
            <ValidationError>{errors.email?.message}</ValidationError>
          </FormGroup>
          <ButtonGroup>
            <Button primary type="submit" fullWidth>
              Submit
            </Button>
          </ButtonGroup>
        </Form>
      </Container>
    </RelativeWrapper>
  );
};

const SubHeading = styled.p`
  font-size: 18px;
  color: #707070;
`;

const ValidationError = styled.p`
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  color: red;
`;

const WhiteHeading = styled.span`
  color: #fff;
`;

const RedHeading = styled.span`
  color: #c00f0f;
`;

export default ForgetPasswordPage;
