import React from "react";
import AuthContext from "./authContext";
import useAuth from "../../hooks/useAuth";

const AuthProvider = ({ children }) => {
  const [
    isLoggedIn,
    login,
    logout,
    signUp,
    loadUser,
    forgetPassword,
    verifyRegisteration,
    verifyForgetPassword,
    resetPassword,
    verify,
    handleMetamask,
    loggedInResetPassword,
    updateUserProfile
  ] = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        signUp,
        loadUser,
        forgetPassword,
        verifyRegisteration,
        verifyForgetPassword,
        resetPassword,
        verify,
        handleMetamask,
        loggedInResetPassword,
        updateUserProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
