import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import Container from "../components/layout/Container";
import { Redirect } from "react-router-dom";
import Heading from "../components/typography/Heading";
import Button from "../components/buttons/Button";
import { Form } from "../components/forms/Form";
import { FormGroup } from "../components/forms/FormGroup";
import { ButtonGroup } from "../components/forms/ButtonGroup";
import RelativeWrapper from "../components/layout/RelativeWrapper";
import useScrollToTopOnPageLoad from "../hooks/useScrollToTopOnPageLoad";
import authContext from "../context/auth/authContext";
import { TiledBackgroundImage } from "../components/decoration/TiledBackgroundImage";
import styled from "styled-components";

const ForgetPasswordVerificationPage = () => {
  const { verifyForgetPassword, isLoggedIn } = useContext(authContext);
  const [OTP, setOTP] = useState("");

  useScrollToTopOnPageLoad();

  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <RelativeWrapper>
      <TiledBackgroundImage />
      <Container
        fullHeight
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="6rem 2rem 2rem 2rem"
        contentCenteredMobile
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const email = JSON.parse(localStorage.getItem("SP_F_EMAIL"));
            OTP && OTP.length === 4 && verifyForgetPassword(email, OTP);
          }}
        >
          <Heading headingClass="h2">
            <WhiteHeading>Verify </WhiteHeading>
            <RedHeading>Code</RedHeading>
          </Heading>
          <SubHeading>
            Enter a 4-digit code sent on your email to verify.
          </SubHeading>
          <FormGroup>
            <OtpInput
              inputType="text"
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
              inputStyle={{
                width: "5rem",
                height: "5rem",
                fontSize: "2.5rem",
                borderColor: "#707070",
                background: "#F8F5E6",
                color: "#707070",
                borderRadius: "5px",
              }}
              value={OTP}
              onChange={setOTP}
              numInputs={4}
              renderSeparator={<span />}
              renderInput={(props) => <input {...props} />}
            />
          </FormGroup>
          <ButtonGroup>
            <Button primary type="submit" fullWidth>
              Verify
            </Button>
          </ButtonGroup>
        </Form>
      </Container>
    </RelativeWrapper>
  );
};

const SubHeading = styled.p`
  font-size: 18px;
  color: #707070;
`;

const WhiteHeading = styled.span`
  color: #fff;
`;

const RedHeading = styled.span`
  color: #c00f0f;
`;

export default ForgetPasswordVerificationPage;
