import styled from "styled-components";

export const FormGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.5rem 0;
`;
