import { useEffect, useState, useContext } from "react";
import Axios from "axios";
import setAuthToken from "../helpers/setAuthToken";
import globalContext from "../context/global/globalContext";
import { toast } from "react-toastify";
import {
  handleSign,
  isMetaMaskInstalled,
  redirectToMetaMaskExtension,
  validateMetamask,
} from "../helpers/web3";

const useAuth = () => {
  localStorage.token && setAuthToken(localStorage.token);
  const serverURL = process.env.REACT_APP_SERVER_API_URL;

  const {
    setId,
    setIsLoading,
    setUserName,
    setEmail,
    setChipsAmount,
    setPicture,
  } = useContext(globalContext);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const token = localStorage.token;
    token && loadUser(token);

    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const signUp = async (name, email, password) => {
    try {
      setIsLoading(true);
      const res = await Axios.post(`${serverURL}/users`, {
        name,
        email,
        password,
      });

      if (res?.data?.success) {
        toast.success(res?.data?.message);
        // localStorage.setItem("SP_EMAIL", JSON.stringify(email));
        // window.location.replace("/register-verification");
      }
      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (emailAddress, password) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(`${serverURL}/auth`, {
        email: emailAddress,
        password,
      });

      const token = res.data.token;

      if (token) {
        localStorage.setItem("token", token);
        setAuthToken(token);
        await loadUser(token);
      }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const forgetPassword = async (email) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(`${serverURL}/auth/forgetPassword`, {
        email,
      });
      if (res?.data?.status) {
        localStorage.setItem("SP_F_EMAIL", JSON.stringify(email));
        toast.success(res?.data?.message);
      }
      // if (res?.status === 200) {
      //   localStorage.setItem("SP_F_EMAIL", JSON.stringify(email));
      //   window.location.replace("/forget-verification");
      // }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const verifyForgetPassword = async (email, otp) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(
        `${serverURL}/auth/passwordOtpVerification`,
        {
          email,
          otp,
        }
      );
      const token = res.data.auth_Token;
      if (token) {
        localStorage.setItem("F_TOKEN", JSON.stringify(token));
        window.location.replace("/reset-password");
      }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const loggedInResetPassword = async (
    email,
    password,
    confirm_password,
    token
  ) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(`${serverURL}/auth/resetPassword`, {
        email,
        password,
        confirm_password,
      });
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      }
      if (!res?.data?.status) {
        return toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
    setIsLoading(false);
  };

  const updateUserProfile = async (image, name) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("name", name);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const res = await Axios.post(
        `${serverURL}/users/updateProfile`,
        formData,
        config
      );

      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setUserName(res?.data?.updatedUser?.fullName);
        setPicture(res?.data?.updatedUser?.picture);
      }

      if (!res?.data?.status) {
        return toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
    setIsLoading(false);
  };

  const resetPassword = async (email, password, confirm_password, token) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(`${serverURL}/auth/updatePassword`, {
        email,
        password,
        confirm_password,
        token,
      });
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        const token = res.data.token;
        if (token) {
          localStorage.removeItem("SP_F_EMAIL");
          localStorage.setItem("token", token);
          setAuthToken(token);
          await loadUser(token);
          // window.location.reload();
        }
      }
      if (!res?.data?.status) {
        return toast.error(res?.data?.message);
      }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const verify = async (token) => {
    try {
      const res = await Axios.post(`${serverURL}/auth/verify`, {
        token,
      });
      if (res?.data?.success) {
        const token = res.data.token;
        if (token) {
          // localStorage.removeItem("SP_EMAIL");
          localStorage.setItem("token", token);
          setAuthToken(token);
          await loadUser(token);
        }
        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const verifyRegisteration = async (email, otp) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(`${serverURL}/auth/otpVerification`, {
        email,
        otp,
      });
      const token = res.data.token;
      if (token) {
        localStorage.removeItem("SP_EMAIL");
        localStorage.setItem("token", token);
        setAuthToken(token);
        await loadUser(token);
      }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const loadUser = async (token) => {
    try {
      const res = await Axios.get(`${serverURL}/auth`, {
        headers: {
          "x-auth-token": token,
        },
      });

      const { _id, name, email, chipsAmount, picture } = res.data;

      setIsLoggedIn(true);
      setId(_id);
      setUserName(name);
      setPicture(picture);
      setEmail(email);
      setChipsAmount(chipsAmount);
    } catch (error) {
      localStorage.removeItem(token);
      alert(error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setId(null);
    setUserName(null);
    setEmail(null);
    setPicture(null);
    setChipsAmount(null);
  };

  // ** MetaMask ** //

  const handleMetamask = () => {
    if (isMetaMaskInstalled()) {
      return validateUser();
    }
    redirectToMetaMaskExtension();
  };
  const validateUser = async () => {
    try {
      setIsLoading(true);
      // const currentChainId = await getChainId();
      // if (ENV.chainId !== currentChainId) {
      //   await switchNetworkToChainId(ENV.chainId);
      // }
      // const connectedAddress = await connectWithMetamask();
      // if (!connectedAddress) {
      //   return alert("address not foound");
      // }
      const connectedAddress = await validateMetamask();
      // const res = await handleRequest(
      //   "post",
      //   `/transaction/auth/metamask/signUser/${connectedAddress}`
      // );

      const res = await Axios.post(
        `${serverURL}/metamask/auth/signUser/${connectedAddress}`
      );
      // console.log(res, "res==>");
      const { users } = res.data;
      if (users?._id) {
        const { address, signature } = await handleSign(users?.nounce);
        loginUser(address, signature);
      } else {
        // console.log("settingg metamask signup=>");
        // setMetamaskSignUp(true);
        signUpUser(connectedAddress);
      }
    } catch (err) {
      console.log(err, "error=>");
    } finally {
      setIsLoading(false);
    }
  };

  const signUpUser = async (connectedAddress) => {
    try {
      setIsLoading(true);
      // const res = await handleRequest(
      //   "post",
      //   `/transaction/auth/metamask/register/${connectedAddress}`
      // );

      const res = await Axios.post(
        `${serverURL}/metamask/auth/register/${connectedAddress}`
      );

      if (res.data.success) {
        const { address, signature } = await handleSign(res.data.data.nounce);
        await loginUser(address, signature);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      console.log(err, "error while signing=>");
    } finally {
      setIsLoading(false);
    }
  };

  const loginUser = async (address, signature) => {
    try {
      setIsLoading(true);
      // const res = await handleRequest(
      //   "post",
      //   `/transaction/auth/metamask/login/${address}`,
      // {
      //   address,
      //   signature,
      //   role: "user",
      // }
      // );
      const res = await Axios.post(
        `${serverURL}/metamask/auth/login/${address}`,
        {
          address,
          signature,
          role: "user",
        }
      );
      // console.log(res, "res of login");
      if (res.data.success) {
        // console.log(
        //   res.data?.data?._doc,
        //   "res.data?.data?._doc",
        //   res.data.data?._doc?._id
        // );
        // ENV.encryptUserData({
        //   // accessToken: res.data.data.accessToken,
        //   // ...res.data.data._doc,
        // });
        // setUserDetail({
        //   ...res.data?.data?._doc,
        //   userID: res.data.data?._doc?._id,
        // });
        // localStorage.setItem(
        //   "userDetail",
        //   JSON.stringify({
        //     ...res.data?.data?._doc,
        //     userID: res.data.data?._doc?._id,
        //   })
        // );
        // handleClose();
        localStorage.setItem("token", res.data.token);
        setAuthToken(res.data.token);
        await loadUser(res.data.token);
        toast.success("User logged in successfully");
        // console.log(metamaskSignUp, "metamaskSignUp after login=>");
        // dispatch({ type: "SET_USER_LOGGED_IN", data: true });
        // setIsLoggedIn(true);

        // navigate("/");
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      console.log(err, "error while login");
    } finally {
      setIsLoading(false);
    }
  };

  return [
    isLoggedIn,
    login,
    logout,
    signUp,
    loadUser,
    forgetPassword,
    verifyRegisteration,
    verifyForgetPassword,
    resetPassword,
    verify,
    handleMetamask,
    loggedInResetPassword,
    updateUserProfile,
  ];
};

export default useAuth;
