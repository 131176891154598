import React from "react";
import PropTypes from "prop-types";
import EyeIcon from "../../assets/icons/Eye.svg";
import styled from "styled-components";

const StyledShowPasswordButton = styled.div`
  position: absolute;
  z-index: 40;
  right: 15px;
  bottom: 3px;
  cursor: pointer;

  svg {
    width: 30px;
  }
`;

const ShowPasswordButton = () => {
  return (
    <StyledShowPasswordButton>
      <img style={{ width: "30px", height: "30px" }} src={EyeIcon} alt="Eye" />
    </StyledShowPasswordButton>
  );
};

ShowPasswordButton.propTypes = {
  clickHandler: PropTypes.func,
};

export default ShowPasswordButton;
