import React from "react";
import Container from "../components/layout/Container";
import CenteredBlock from "../components/layout/CenteredBlock";
import Heading from "../components/typography/Heading";
import Button from "../components/buttons/Button";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useScrollToTopOnPageLoad from "../hooks/useScrollToTopOnPageLoad";

const AuthenticationFailPage = () => {
  useScrollToTopOnPageLoad();

  return (
    <Container fullHeight contentCenteredMobile padding="4rem 2rem 2rem 2rem">
      <CenteredBlock>
        <Heading as="h2" headingClass="h1" textCenteredOnMobile>
          Authentication failed!
        </Heading>
        <Heading as="h3" headingClass="h5" textCenteredOnMobile>
          Please try again later.
        </Heading>
        <Wrapper>
          <Button
            as={Link}
            to="/login"
            large
            primary
            fullWidthOnMobile
            autoFocus
          >
            Back to Login
          </Button>
        </Wrapper>
      </CenteredBlock>
    </Container>
  );
};

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    margin-top: 1.5rem;
  }
`;

export default AuthenticationFailPage;
