const theme = {
  // Colors
  colors: {
    // Primary Brand Colors
    primaryCta: "hsl(0,85.5%,40.6%)",
    primaryCtaDarker: "hsl(202, 49%, 18%)",
    secondaryCta: "hsl(202, 36%, 55%)",
    secondaryCtaDarker: "hsl(202, 36%, 45%)",
    secondaryCtaDarkest: "hsl(202, 36%, 35%)",
    lightGrayColor: "hsl(0,0%,43.9%)",
    // Secondary Brand Colors
    darkBg: "hsl(43, 40%, 60%)",
    lightBg: "hsl(43, 40%, 81%)",
    lightestBg: "hsl(43, 40%, 86%)",
    // Font Colors
    fontColorLight: "hsl(40, 100%, 99%)",
    fontColorDark: "hsl(36, 71%, 3%)",
    fontColorDarkLighter: "hsl(36, 71%, 13%)",
    // Other colors
    playingCardBg: "hsl(49, 63%, 92%)",
    playingCardBgLighter: "hsl(49, 63%, 94%)",
    goldenColorDarker: "hsl(43, 40%, 60%)",
    goldenColor: "hsl(43, 40%, 65%)",
    dangerColorLighter: "hsl(0, 100%, 56%)",
    dangerColor: "hsl(0, 100%, 46%)",
    inputBackgroundColor: "hsl(50,56.3%,93.7%)",
  },
  // Fonts
  fonts: {
    // Font Familys
    fontFamilySerif: "'Playfair Display', serif",
    fontFamilySansSerif: "'Roboto', sans-serif",
    // Font Sizes
    fontLineHeight: "1.4",
    fontSizeRoot: "1em",
    fontSizeRootMobile: "0.9em",
    fontSizeH1: "calc(1.25rem + 4vmin)",
    fontSizeH2: "calc(1.25rem + 3.5vmin)",
    fontSizeH3: "calc(1.25rem + 3vmin)",
    fontSizeH4: "calc(1.25rem + 2vmin)",
    fontSizeH5: "calc(1.25rem + 1.5vmin)",
    fontSizeH6: "calc(1.25rem + 1vmin)",
    fontSizeParagraph: "1.2rem",
  },
  // Other styles
  other: {
    // Border-radius
    stdBorderRadius: "2rem",
    standardBorderRadius: "5px",
    // Drop Shadows
    cardDropShadow: "10px 10px 30px rgba(0, 0, 0, 0.1)",
    navMenuDropShadow: "-10px 0px 30px rgba(0, 0, 0, 0.1)",
  },
};

export default theme;
