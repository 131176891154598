import React from 'react';

const Loader = () => (
  <>
    <svg
      width="128"
      height="128"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="custom-loader"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.982 31.903L15.9784 35.7712C13.7496 37.9247 11.9335 39.7591 11.9335 39.8787C11.9335 39.9984 15.6069 40.0383 20.0645 39.9585L28.1956 39.8389L19.982 31.903ZM19.9407 35.0933L17.1341 37.8848H19.8994C21.4266 37.8848 22.6648 37.8449 22.6648 37.7652C22.6648 37.6854 22.0457 37.0873 21.3028 36.3695L19.9407 35.0933Z"
        fill="#6297B5"
      />
      <path
        d="M19.982 15.9515L15.4418 20.3382C11.108 24.5254 10.3651 25.0439 9.74598 24.4457C8.96177 23.7279 9.8698 22.4916 14.7402 17.8258L19.982 12.7612L25.5127 18.105C30.3006 22.7309 31.0022 23.5683 30.5482 24.0868C30.218 24.4457 29.764 24.7248 29.5163 24.7248C29.2687 24.7248 26.9986 22.7309 24.5222 20.3382L19.982 15.9515Z"
        fill="#6297B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8828 28.6728C13.3781 28.4335 15.1942 26.9182 16.8864 25.2831L19.982 22.3321L22.8712 25.1635C26.1731 28.3937 27.3701 29.1115 29.4751 29.1115C33.1485 29.1115 35.9139 25.9212 35.2535 22.4119C35.0058 21.1756 33.7263 19.74 27.4939 13.6385L19.982 6.3806L13.0479 13.0404C9.20941 16.7491 5.82493 20.2185 5.49473 20.8167C4.7518 22.2125 4.7518 24.8445 5.53601 26.3599C6.81551 28.6728 10.4889 29.8293 12.8828 28.6728ZM7.18698 22.7309C6.81551 24.326 7.64099 25.8813 9.08559 26.4795C9.78725 26.7586 10.7778 26.9182 11.2731 26.8384C11.8097 26.7188 13.956 24.9641 16.1022 22.8904L19.9407 19.1418L23.9443 22.9303C27.7003 26.5194 28.3607 26.9182 30.3006 26.7586C31.5388 26.639 32.9834 24.8843 32.9834 23.4487C32.9834 22.372 32.0753 21.3351 26.5033 15.8717L20.0233 9.5709L13.7083 15.6325C9.82853 19.3412 7.3108 22.0928 7.18698 22.7309Z"
        fill="#6297B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9465 32.7006C15.9371 32.2619 17.4643 31.1852 18.331 30.3078L19.8994 28.7526L21.7155 30.3477C22.7061 31.2251 24.3158 32.3018 25.2651 32.7405C27.3288 33.6178 31.5801 33.7374 33.5612 32.9398C37.2346 31.4643 40 27.4366 40 23.5285C40 19.6203 38.9681 18.2645 28.9798 8.65369L19.9407 0L10.6127 9.05248C1.94515 17.5068 1.24349 18.3043 0.624372 20.2983C-0.201113 22.9702 -0.201113 24.0469 0.583098 26.5592C1.8626 30.7465 5.82493 33.4981 10.5715 33.4981C12.3463 33.4981 13.7496 33.219 14.9465 32.7006ZM8.01246 31.0257C3.38975 29.4704 1.16094 24.326 3.26592 20.1786C3.76121 19.1817 7.26953 15.4331 12.0573 10.847L20.0233 3.1903L28.5258 11.4452C36.4504 19.2216 37.0283 19.8596 37.441 21.694C38.349 25.8813 35.8313 30.0287 31.7039 31.1054C28.3607 31.9828 25.6366 31.0257 22.2521 27.7157L20.0233 25.5224L17.2992 28.0746C13.8321 31.3048 11.2731 32.1423 8.01246 31.0257Z"
        fill="#6297B5"
      />
    </svg>
    <svg
      width="264"
      height="25"
      viewBox="0 0 264 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: '3rem' }}
    >
    </svg>
  </>
);

export default Loader;
