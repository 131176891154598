import React, { useContext, useEffect } from "react";
import authContext from "../context/auth/authContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Container from "../components/layout/Container";

const RegisterVerificationPage = (props) => {
  const { isLoggedIn, verify } = useContext(authContext);
  const token = new URLSearchParams(props.location.search).get("token");

  useEffect(() => {
    verify(token);
  }, [token]);

  if (isLoggedIn) return <Redirect to="/" />;

  return <Container />;
};

export default RegisterVerificationPage;
