import React, { useState, useContext } from "react";
import Container from "../components/layout/Container";
import { Redirect } from "react-router-dom";
import Button from "../components/buttons/Button";
import { Input } from "../components/forms/Input";
import { Form } from "../components/forms/Form";
import { FormGroup } from "../components/forms/FormGroup";
import { ButtonGroup } from "../components/forms/ButtonGroup";
import RelativeWrapper from "../components/layout/RelativeWrapper";
import ShowPasswordButton from "../components/buttons/ShowPasswordButton";
import useScrollToTopOnPageLoad from "../hooks/useScrollToTopOnPageLoad";
import authContext from "../context/auth/authContext";
import { TiledBackgroundImage } from "../components/decoration/TiledBackgroundImage";

import styled from "styled-components";
import Heading from "../components/typography/Heading";

// ** React Hook Form Imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
        "Password must include at least one capital letter, one digit, and one special character"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
        "Password must include at least one capital letter, one digit, and one special character"
      )
      .oneOf([yup.ref("password"), null], "Passwords do not match")
      .required("Confirm Password is required"),
  })
  .required();

const ResetPasswordPage = (props) => {
  const { resetPassword, isLoggedIn } = useContext(authContext);
  const token = new URLSearchParams(props.location.search).get("token");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const email = JSON.parse(localStorage.getItem("SP_F_EMAIL"));
    // const token = JSON.parse(localStorage.getItem("F_TOKEN"));
    const { password, confirmPassword } = data;
    resetPassword(email, password, confirmPassword, token);
  };

  useScrollToTopOnPageLoad();

  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <RelativeWrapper>
      <TiledBackgroundImage />
      <Container
        fullHeight
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="6rem 2rem 2rem 2rem"
        contentCenteredMobile
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Heading headingClass="h2">
            <WhiteHeading>Reset </WhiteHeading>
            <RedHeading>Password</RedHeading>
          </Heading>
          <FormGroup>
            <FormGroup style={{ marginBottom: "0px" }}>
              <div onClick={() => setShowPassword(!showPassword)}>
                <ShowPasswordButton />
              </div>
              <Input
                type={showPassword ? "text" : "password"}
                {...register("password")}
                autoComplete="new-password"
                placeholder="Enter password"
              />
            </FormGroup>
            <ValidationError>{errors.password?.message}</ValidationError>
          </FormGroup>

          <FormGroup>
            <FormGroup style={{ marginBottom: "0px" }}>
              <div onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                <ShowPasswordButton />
              </div>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword")}
                autoComplete="new-password"
                placeholder="Confirm password"
              />
            </FormGroup>
            <ValidationError>{errors.confirmPassword?.message}</ValidationError>
          </FormGroup>

          <ButtonGroup>
            <Button primary type="submit" fullWidth>
              Reset
            </Button>
          </ButtonGroup>
        </Form>
      </Container>
    </RelativeWrapper>
  );
};

const ValidationError = styled.p`
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  color: red;
`;

const WhiteHeading = styled.span`
  color: #fff;
`;

const RedHeading = styled.span`
  color: #c00f0f;
`;

export default ResetPasswordPage;
