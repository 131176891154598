import React, { useState, useContext } from "react";
import Container from "../components/layout/Container";
import { Redirect } from "react-router-dom";
import Button from "../components/buttons/Button";
import { Input } from "../components/forms/Input";
import { Form } from "../components/forms/Form";
import { FormGroup } from "../components/forms/FormGroup";
import { ButtonGroup } from "../components/forms/ButtonGroup";
import RelativeWrapper from "../components/layout/RelativeWrapper";
import ShowPasswordButton from "../components/buttons/ShowPasswordButton";
import useScrollToTopOnPageLoad from "../hooks/useScrollToTopOnPageLoad";
import authContext from "../context/auth/authContext";
import { TiledBackgroundImage } from "../components/decoration/TiledBackgroundImage";

import styled from "styled-components";
import Facebook from "../assets/icons/Facebook.svg";
import Google from "../assets/icons/Google.svg";
import MetaMask from "../assets/icons/MetaMask.svg";
import Heading from "../components/typography/Heading";

// ** React Hook Form Imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[^\s].*$/, "Name should not start with a space")
      .required("Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
        "Password must include at least one capital letter, one digit, and one special character"
      )
      .required("Password is required"),
  })
  .required();

const RegistrationPage = () => {
  const { signUp, isLoggedIn, handleMetamask } = useContext(authContext);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { email, name, password } = data;
    signUp(name, email, password);
  };

  const handleGoogleLogin = () => {
    const url = process.env.REACT_APP_GOOGLE_AUTH_URL || undefined;
    if (url) {
      window.open(url, "_blank");
    }
  };

  useScrollToTopOnPageLoad();

  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <RelativeWrapper>
      <TiledBackgroundImage />
      <Container
        fullHeight
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="6rem 2rem 2rem 2rem"
        contentCenteredMobile
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Heading headingClass="h2">
            <WhiteHeading>Register to </WhiteHeading>
            <RedHeading>Stock Poker</RedHeading>
          </Heading>
          <SubHeading>
            Register to your existing Stock Poker account to continue your
            journey.
          </SubHeading>
          <FormGroup>
            <Input
              type="text"
              {...register("name")}
              autoComplete="off"
              placeholder="Enter your name"
            />
            <ValidationError>{errors.name?.message}</ValidationError>
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              {...register("email")}
              placeholder="Enter email"
            />
            <ValidationError>{errors.email?.message}</ValidationError>
          </FormGroup>
          <FormGroup>
            <FormGroup style={{ marginBottom: "0px" }}>
              <div onClick={() => setShowPassword(!showPassword)}>
                <ShowPasswordButton />
              </div>
              <Input
                type={showPassword ? "text" : "password"}
                {...register("password")}
                autoComplete="new-password"
                placeholder="Enter password"
              />
            </FormGroup>
            <ValidationError>{errors.password?.message}</ValidationError>
          </FormGroup>
          <ButtonGroup>
            <Button primary type="submit" fullWidth>
              Register
            </Button>
          </ButtonGroup>
          <FooterGroup>
            <ORText>OR</ORText>
            <hr style={{ minWidth: "300px", maxWidth: "400px" }} />
            <ThirdPartyLoginswrapper>
              <ProviderLogo onClick={handleGoogleLogin}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={Google}
                  alt="Google"
                />
              </ProviderLogo>
              <ProviderLogo onClick={handleMetamask}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={MetaMask}
                  alt="MetaMask"
                />
              </ProviderLogo>
              <ProviderLogo>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={Facebook}
                  alt="Facebook"
                />
              </ProviderLogo>
            </ThirdPartyLoginswrapper>
          </FooterGroup>
        </Form>
      </Container>
    </RelativeWrapper>
  );
};

const ProviderLogo = styled.div`
  padding: 12px 49px 12px 49px;
  border-radius: 5px;
  border: 1px solid #707070;
  opacity: 0px;
  cursor: pointer;
`;

const ThirdPartyLoginswrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const FooterGroup = styled.div`
  isplay: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ORText = styled.p`
  font-size: 18.26px;
  font-weight: 600;
  line-height: 29.21px;
  color: #707070;
`;

const SubHeading = styled.p`
  font-size: 18px;
  color: #707070;
`;

const ValidationError = styled.p`
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  color: red;
`;

const WhiteHeading = styled.span`
  color: #fff;
`;

const RedHeading = styled.span`
  color: #c00f0f;
`;

export default RegistrationPage;
